.table-container {
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.styled-table {
  width: 100%;
  overflow: hidden;
  background-color: #0d0b12;
  border-radius: 5px;
  border-radius: 20px;
}

.styled-table th,
.styled-table td {
  padding: 10px;
  text-align: center;
}

.styled-table th {
  background-color: #1c1b26;
  
  color: white;
}

.positive {
  color: green;
  font-weight: bold;
}

.negative {
  color: red;
  font-weight: bold;
}
